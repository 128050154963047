import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Css from "./HeaderTopDiv.module.css";
import { faFacebookSquare, faInstagramSquare, faWhatsapp, faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faLocationDot, faSquareEnvelope, faSquarePhone } from "@fortawesome/free-solid-svg-icons";
import { RETagManager } from "../../../utils/gtm-utils";

function HeaderTopDiv() {
    return (
        <div className={Css.topMainDiv}>
            <div className={`${Css.topMidDiv} container`}>
                <div className="d-flex flex-column justify-content-center">
                    <div className="d-none d-lg-block">
                        <a 
                            href="https://wa.me/573228547342"
                            className="no-dec-link re-color-white"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {RETagManager.sendWhatsAppAttemptEvent()}}
                        >
                            <FontAwesomeIcon icon={faWhatsapp} />
                            <span>  +57 322 854 7342</span>
                        </a>
                        <a
                            href="mailto:contacto@restrepoecheverri.com"
                            className="ms-3 no-dec-link re-color-white"
                            onClick={() => RETagManager.sendEmailAtemptEvent()}
                        >
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span>  contacto@restrepoecheverri.com</span>
                        </a>
                        <a
                            href="https://goo.gl/maps/ADKTTLk7pv41b3De6"
                            className="ms-3 no-dec-link re-color-white"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faLocationDot} />
                            <span>  Oficina 504. Edificio Andi. Manizales</span>
                        </a>
                    </div>
                </div>
                <div>
                    <div>
                        <a
                            href="tel:+573228547342"
                            className={Css.socialLink + " ms-2"}
                            onClick={() => RETagManager.sendCallAtemptEvent()}
                        >
                            <FontAwesomeIcon icon={faSquarePhone} />
                            <span className="sr-only">Teléfono</span>
                        </a>
                        <a 
                            href="https://wa.me/573228547342"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={Css.socialLink + " ms-2 d-inline d-lg-none"}
                            onClick={() => RETagManager.sendWhatsAppAttemptEvent()}
                        >
                            <FontAwesomeIcon icon={faWhatsappSquare} />
                            <span className="sr-only">Whatsapp</span>
                        </a>
                        <a
                            href="mailto:contacto@restrepoecheverri.com"
                            className={Css.socialLink  + " ms-2 d-inline d-lg-none"}
                            onClick={() => RETagManager.sendEmailAtemptEvent()}
                        >
                            <FontAwesomeIcon icon={faSquareEnvelope} />
                            <span className="sr-only">Correo</span>
                        </a>
                        <a 
                            href="https://www.instagram.com/inmobiliariarestrepoecheverri/"
                            className={Css.socialLink  + " ms-2"}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => RETagManager.sendInstagramLinkClickEvent()}
                        >
                            <FontAwesomeIcon icon={faInstagramSquare} />
                            <span className="sr-only">Instagram</span>
                        </a>
                        <a
                            href="https://www.facebook.com/inmobiliariarestrepoecheverri"
                            className={Css.socialLink  + " mx-2"}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => RETagManager.sendFacebookClickEvent()}
                        >
                            <FontAwesomeIcon icon={faFacebookSquare} />
                            <span className="sr-only">Facebook</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopDiv;